@use '../../scss/variables' as *;

header {
    box-sizing: border-box;
    height: 100px;
    padding: $size-padding-small $size-padding-large;
    width: 100%;
    box-shadow: 0 0 5px 5px rgba(85,85,85,.05);
    position: relative;
    z-index: 999;

    div.header-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin: 0 auto;
        max-width: $size-max-page-width;
        height: 100%;
        width: 100%;

        a {
            color: black;
            font-weight: bold;
            text-decoration: none;
        }

        div.logo {
            box-sizing: border-box;
            padding: 15px 0;
            width: 250px;
            height: 100%;
            
            a {
                div.logo-image {
                    background: url('#{$path-images}/appkwekerij-2022.png') no-repeat left center / contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    
        app-menu {
            display: block;
            height: 100%;
            
            ul {
                display: flex;
                flex-flow: row nowrap;
                height: inherit;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    height: inherit;
                    margin: 0 $size-padding-medium;

                    &.current, &:hover {
                        a::after {
                            opacity: 1;
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        align-items: center;
                        display: flex;
                        flex-flow: column nowrap;
                        height: inherit;
                        justify-content: center;

                        &::after {
                            background-color: $color-main-1;
                            border-radius: 2px;
                            content: '';
                            display: block;
                            height: 3px;
                            top: 2px;
                            opacity: 0;
                            position: relative;
                            transition: opacity .2s ease-in-out, transform .2s ease-in-out;
                            width: 100%;
                        }    
                    }
                }
            }
        }
    }
}