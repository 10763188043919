@keyframes fadeIn {
    from { opacity: 0 }
    to { opacity: 1 }
}

html, body, div#root {
    height: 100vh;
    font-family: 'sofia-pro', sans-serif;
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    width: 100vw;
}

app-view {
    color: white;
    display: block;
    height: 100vh;
    width: 100vw;
}

app-component.view-holder {
    display: block;
    height: inherit;
    width: inherit;

    app-view {
        height: unset;
        min-height: calc(100vh - 130px);
    }
}

div.transition {
    * {
        animation: fadeIn ease-in-out 0.75s;
        animation-fill-mode: forwards;
        flex-shrink: 0;

        &.animations-off {
            &, * {
                animation: unset;
            }
        }
    }
}

div.flex-spacer {
    &.half { flex: 0.5 }
    &:not(.half) { flex: 1 }
}