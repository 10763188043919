@use '../../scss/variables' as *;

app-view.home {
    div.background {
        box-sizing: border-box;
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        background: linear-gradient(to bottom, white 0%, rgb(244, 244, 244) 100%);
        height: calc(100vh - 130px);
        width: 100%;
        padding: 0 $size-padding-large;

        div.background-container {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            max-width: $size-max-page-width;
            width: 100%;
            margin: 0 auto;

            div.content-container {
                color: black;
                max-width: 350px;
    
                h1 {
                    // font-size: 1.8em;
                }
    
                p {
                    font-weight: bold;
    
                    a {
                        color: $color-main-1;
                        text-decoration: none;
    
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            div.illustration {
                background: url('#{$path-svg}/work-together.svg') no-repeat center / contain;
                width: 50vw;
                height: 25vw;
            }
        }
    }
}