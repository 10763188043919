@use '../../scss/variables.scss' as *;

footer {
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    padding: 7.5px $size-padding-large;
    background-color: lighten($color-main-line, 5%);
    width: 100%;

    div.footer-content {
        max-width: $size-max-page-width;
        margin: 0 auto;
        width: 100%;

        div.copyright {
            color: darken($color-main-line, 25%);
            font-size: 0.8em;
        }
    }

}