// Animations.
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to { 
        opacity: 1;
    } 
}

// Paths.
$path-images: '../../res/images/' !default;
$path-svg: '../../res/svg/' !default;

// Sizes.
$size-mobile-width: 768px;
$size-padding-large: 40px;
$size-padding-medium: 20px;
$size-padding-small: 10px;
$size-max-page-width: 1200px;

$color-main-1: rgb(55, 186, 114);
$color-main-line: #ebebeb;

// Apple Human Design Colors.
$color-apple-blue: rgb(0, 122, 255);
$color-apple-green: rgb(52, 199, 89);
$color-apple-indigo: rgb(88, 86, 214);
$color-apple-orange: rgb(255, 149, 0);
$color-apple-pink: rgb(255, 45, 85);
$color-apple-purple: rgb(175, 82, 222);
$color-apple-red: rgb(255, 59, 48);
$color-apple-teal: rgb(90, 200, 250);
$color-apple-yellow: rgb(255, 204, 0);

$color-apple-blue-dark: rgb(10, 132, 255);
$color-apple-green-dark: rgb(48, 209, 88);
$color-apple-indigo-dark: rgb(94, 92, 230);
$color-apple-orange-dark: rgb(255, 159, 10);
$color-apple-pink-dark: rgb(255, 55, 95);
$color-apple-purple-dark: rgb(191, 90, 242);
$color-apple-red-dark: rgb(255, 69, 58);
$color-apple-teal-dark: rgb(100, 210, 255);
$color-apple-yellow-dark: rgb(255, 214, 10);

// Easings.
$easing-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easing-quint: cubic-bezier(0.86, 0, 0.07, 1);
$easing-uber: cubic-bezier(1, 0, 0, 1);